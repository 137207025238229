import footer_images from "./constant_datas/footer_images";
import {pages, btn} from "./languages/buttons";
import {languagesPath as lng} from "./languages/langOptions/languages";

const casino = {
    name: "Pure",
    rival: {
        id: 257,
        sortName: "purecasino",
        chatName: "Pure Casino",
    },
    GA_TRACKING:'G-PQS08SMDM0',
    support_email: "support@purecasino.co",
    support_documents: "documents@pure.casino",
    url: "purecasinocash.co",
    CAF: "https://www.google.com",
    leaderboard: "https://docs.google.com/spreadsheets/d/e/2PACX-1vT2uATYXc0Nsg8GJt7q9c0uiLaCr-eWt2b5d5LE9pJs0jQPI_WXwUFgozEKTsppWqKh0-UYZm9RcN9x/pub?gid=777395800&single=true&output=csv",
    instantPlayRegister: true,
    languages: [lng.en, lng.de, lng.fr, lng.es, lng.it, lng.pt],
    layout: {
        header: {
            burger_menu_color: "#4FD1C5",
            header_pages: [],
            side_bar: {
                someting: [pages.promotions, pages.leaderboard],
                pages: [
                    pages.about,
                    pages.support,
                    pages.faq,
                    pages.aml,],
                games: [
                    pages.banking,
                    pages.bitcoin,
                    pages.cookie_policy,
                ],
            }
        },
        footer: {
            footer_bottom: {
                text:
                    <div className='footer_bottom_text'>
                        <div style={{display: 'flex',flexDirection: 'row'}}>
                            <div style={{display: 'flex',alignItems: 'center'}}>
                                <img style={{width:'80%', height:'auto', marginBottom:'15px'}} src='/brand/images/pa_de_bono.png'/>
                            </div>
                            <a href='https://nam12.safelinks.protection.outlook.com/ap/b-59584e83/?url=https%3A%2F%2Fknoxxit2.sharepoint.com%2F%3Ab%3A%2Fs%2FCIL-Validations%2FEd3iHFgt3JdPu6e4rb_kQ2ABBde69HlDd9jMUHUoW1OLhQ%3Fe%3DR9Mhn8&data=05%7C02%7Cbryon.finies%40hbmgroup.com%7Cf73d721de4b64ab5b7c608dc3f0b53d9%7Cab6f126377d444e78de12c3ddcaa94ef%7C0%7C0%7C638454565086344840%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=UNg5XOyU8OZhHhQgPDppx8rNxQAEw%2FtsqoDUohoDu7U%3D&reserved=0' target="_blank" >
                                <img style={{width:'120px',height:'auto', marginBottom:'15px'}} src='/brand/images/Curacao_Int_Lic.png'/>
                            </a>
                        </div>

                        <span>Name: Pan De Bono Consulting Limited - HE401911; Address: 84, Spyrou Kyprianou Avenue, 2nd floor, 4004, Limassol, Cyprus</span>
                        <span>License holder: Windward Circle B.V - 150466; Address: Emancipatie Blvd. Dominco F. Don Martina 31, New Haven Office Ctr., Willemstad, Curaçao – Dutch Caribbean</span>
                        <a href='//www.truedynastyaffiliates.com/' title="Become a partner" target="blank" >
                            Become a partner
                        </a>
                    </div>
            },
            footer_top: [pages.chat, pages.language],
            footer_images: [
                footer_images.EightTeen,
                footer_images.Rival,
                footer_images.Visa,
                footer_images.VerifiedByVisa,
                footer_images.MasterCard,
                footer_images.MasterCardSecure,
                footer_images.Noda,
                footer_images.Bitcoin,
                footer_images.Interac
            ],
            pages: [
                pages.about,
                pages.support,
                pages.faq,
                pages.aml,
                pages.banking,
                pages.bitcoin,
                pages.cookie_policy,
                pages.privacy,
                pages.responsible_gaming,
                pages.security,
                pages.terms,
            ],
            general_info: [pages.about, pages.support, pages.faq],
            casino: [pages.aml, pages.banking, pages.bitcoin],
            security_and_privacy: [
                pages.security,
                pages.cookie_policy,
                pages.privacy,
            ],
            terms_and_conditions: [pages.terms, pages.responsible_gaming,],
            promotions: [pages.promotions, pages.leaderboard],
        },
    },
};

export default casino;


