import about from "./languages/pages/about";
import aml from "./languages/pages/aml";
import banking from "./languages/pages/banking";
import bitcoin from "./languages/pages/bitcoin";
import bonus from "./languages/pages/bonus";
import cookie from "./languages/pages/cookie";
import faq from "./languages/pages/faq";
import privacy from "./languages/pages/privacy";
import responsible from "./languages/pages/responsible";
import security from "./languages/pages/security";
import support from "./languages/pages/support";
import terms from "./languages/pages/terms";
import vip from "./languages/pages/vip";
import casino from "./config";
import React from "react";

//en
terms.en.content = [<>Playing at a casino, whether for fun play or real play, constitutes an agreement between the player and the Online Casino and its management, in that the player be bound by the following terms and conditions contained herein. The player accepts that this is a binding agreement between the player and the casino and is the sole agreement governing the player's relationship with the casino, to the exclusion of all other agreements, statements, and representations.
    Each clause contained in these terms and conditions shall be separate and severable from each of the others. If any clause is found to be void, invalid, or unenforceable for any reason, the remaining terms and conditions shall remain of full force and effect. <br></br> <br></br>
    Name: Pan De Bono Consulting Limited; Address: 84, Spyrou Kyprianou Avenue, 2nd floor, 4004, Limassol, Cyprus<br></br><br></br>
    License holder: Windward Circle B.V.; Address: Emancipatie Blvd. Dominco F. Don Martina 31, New Haven Office Ctr., Willemstad, Curaçao – Dutch Caribbean
</>]
let prev = terms.en.sections;
terms.en.sections = [{title: "Governing Law.", content: [<>This Agreement shall be governed by and construed in accordance with the laws of the State of Cyprus</>]}, ...prev];

//International (+1) 910 665 7465                  
support.en.sections[2].content=["France (+33) (0)1 789 00368", "Spain (+34) 91 290 1420", "International (+1) 910 665 7465"]
support.de.sections[2].content=["France (+33) (0)1 789 00368", "Spain (+34) 91 290 1420", "International (+1) 910 665 7465"]
support.fr.sections[2].content=["France (+33) (0)1 789 00368", "Spain (+34) 91 290 1420", "International (+1) 910 665 7465"]
support.es.sections[2].content=["France (+33) (0)1 789 00368", "Spain (+34) 91 290 1420", "International (+1) 910 665 7465"]
support.it.sections[2].content=["France (+33) (0)1 789 00368", "Spain (+34) 91 290 1420", "International (+1) 910 665 7465"]

let docRef = 'mailto:' + casino.support_documents;
let cafRef = {
    en: "/CAF/en.pdf",
    de: "/CAF/de.pdf",
    fr: "/CAF/fr.pdf",
    es: "/CAF/es.pdf",
    it: "/CAF/it.pdf",
}
banking.en.sections = [
    {
        title: "Deposits",
        content: [casino.name + " Casino accepts a variety of deposit methods.", <>These include VISA and MasterCard credit and debit cards, as well as different web wallets and vouchers. Please contact our support team via the 24/7 Chat support or at <a href={'mailto:'+casino.support_email}>{casino.support_email}</a> to inquire about the most suitable payment methods for your country of residence.</>, "The minimum deposit amount is 5 or 10 EUR (or the equivalent in other available currencies) depending on the payment method chosen. The maximum deposit may vary from 200 EUR to 1,000 EUR depending on the method of payment chosen.", casino.name + " Casino accepts only payments made with the options available in the cashier.", "Please make sure you only make deposits using a bank account, VISA/MasterCard bank card, payment wallets or any other payment method registered in your own name. If it is determined during the security checks that you have violated this condition, your winnings may be voided and the original deposit may be refunded to the account used to make that deposit.", casino.name + " Casino reserves the right to refund transactions based on risk assessments. In such cases the deposit is refunded and the credit equivalent to the deposit is removed from the account."]
    },
    {
    title: "Withdrawals",
    content: ["At Casino " + casino.name + ", we are always thrilled when our players get the chance to withdraw their winnings. We believe that it is in our best interest to make this process as streamlined as possible.", "The following documents are required by the casino in order to approve a pending withdrawal. This is a standard industry request and will only be necessary once. The documents are:",
        <ul>
            <li>A Copy of ID or passport (Front and Back).</li>
            <li>A recent Utility Bill showing player name and address as they appear in the account.</li>
            <li>A Copy of any Credit Card or Debit Card used to make a deposit (front and back). Please cover
                all numbers
            </li>
            <li>except for the first four and last four.</li>
            <li>A signed CAF (Card Authorization Form) found here:<a href={cafRef.en} target="_blank" download> PDF</a>.
            </li>
        </ul>, "If you have used an Alternative payment method such as Neteller or Skrill, you will only need to provide:",
        <ul>
            <li>A Copy of ID or passport (Front and Back).</li>
            <li>A recent Utility Bill showing player name and address as they appear in the account.</li>
        </ul>, <>The best way to send in these documents is by taking digital pictures of the above and sending
            them to <a href={docRef}>{casino.support_documents}</a>. Please include
            your name and nickname and please be sure the pictures are not too blurry. Once the Finance
            department has reviewed and approved the verification documents, you name will be added to the next
            available payment cycle.</>]
}, {
    title: "Fraud prevention",
    content: ["The above mentioned verification documents may also be requested for fraud prevention purposes. The casino reserves the right to request and require verification documents in order to validate any account deemed essential.", <>Please
        note, a player will not be able to play until the necessary documents have been received and
        approved by the casino. Verification Documents may also be sent via email to <a
            href={docRef}>{casino.support_documents}</a>.</>, "Please note by completing a transaction you automatically agree to our Terms and Conditions and Privacy Policy.", "A 3% processing fee will be taken on all deposits."]
}];

banking.fr.sections = [
    {
        title: "",
        content: ["Bienvenue dans notre section de paiements, section dans laquelle vous pourrez prendre connaissance des méthodes de paiements pour déposer ou retirer vos gains. Tous les transferts d'argent sont sécurisés grâce aux méthodes de cryptage SSL permettant de sécuriser vos informations personnelles et bancaires. " + casino.name + " Casino s'engage à garantir des transactions bancaires de manière sures et fiables aussi bien lors de vos dépôts que des retraits de vos gains."]
    },
    {
        title: "Dépôts",
        content: [casino.name + " Casino accepte une variété de méthodes de dépôt.", <>Celles-ci comprennent les cartes de crédit et de débit VISA et MasterCard, ainsi que différents portefeuilles électroniques et vouchers. Veuillez contacter notre équipe de support via le support chat 24/7 ou à l'adresse <a href={'mailto:'+casino.support_email}>{casino.support_email}</a> pour vous renseigner sur les méthodes de paiement les plus adaptées à votre pays de résidence.</>, "Le montant minimum de dépôt est de 5 ou 10 EUR (ou l'équivalent dans d'autres devises disponibles) en fonction de la méthode de paiement choisie. Le dépôt maximum peut varier de 200 EUR à 1 000 EUR en fonction de la méthode de paiement choisie.", casino.name + " Casino n'accepte que les paiements effectués avec les options disponibles dans le caissier.", "Veuillez vous assurer de ne faire des dépôts qu'avec un compte bancaire, une carte bancaire VISA/MasterCard, des portefeuilles électroniques ou toute autre méthode de paiement enregistrée à votre nom. S'il est déterminé lors des vérifications de sécurité que vous avez violé cette condition, vos gains peuvent être annulés et le dépôt initial peut être remboursé sur le compte utilisé pour effectuer ce dépôt.", casino.name + " Casino se réserve le droit de rembourser les transactions sur la base des évaluations de risques. Dans de tels cas, le dépôt est remboursé et le crédit équivalent au dépôt est retiré du compte."]
    },
    {
        title: "Documents necessaires au retrait de vos gains",
        content: ["Les documents suivants sont requis par le casino afin d'approuver un retrait en attente. Il s'agit d'une demande de l'industrie standard et ne sera nécessaire qu'une seule fois.", "Les documents sont les suivants:",
            <ul>
                <li>Une copie (recto et verso) de la carte d'identité ou passeport</li>
                <li>Un Justificatif de domicile récent de moins de 3 mois indiquant le nom du joueur et adresse tels
                    qu'ils apparaissent dans le compte de casino
                </li>
                <li>Copie de toute carte de crédit utilisée pour effectuer un dépôt (recto et verso). Merci de
                    cacher les numéros du milieu en laissant apparaitre les 4 premiers et 4 derniers chiffres.
                </li>
                <li>Un CAF signé (formulaire d'autorisation de carte),<a href={cafRef.fr}
                                                                         target="_blank" download> cliquez ici</a>.
                </li>
            </ul>, <>Le moyen le plus populaire pour envoyer ces documents est en prenant simplement une photo
                numérique de chaque document demandé, et les envoyer à <a href={docRef}>{casino.support_documents}</a> en tant que pièces
                jointes. Veuillez s'il vous plaît inclure votre nom et votre nom d'utilisateur.</>]
    },
    {
        title: "Documents pour la prévention de la fraude",
        content: [<>Un joueur ne sera pas en mesure de jouer jusqu'à ce que les documents nécessaires aient été
            reçus et approuvés par le casino. Les documents de vérification peuvent également être envoyés par
            courrier électronique à <a href={docRef}>{casino.support_documents}</a></>]
    }
    ];

banking.de.sections = [
    {
        title: "Einzahlungen",
        content: ["Das " + casino.name + " Casino akzeptiert eine Vielzahl von Einzahlungsmethoden.",
            <>Dazu gehören VISA- und MasterCard-Kredit- und Debitkarten sowie verschiedene Web-Wallets und Gutscheine. Bitte kontaktieren Sie unser Support-Team über den 24/7-Chat-Support oder unter <a href={'mailto:'+casino.support_email}>{casino.support_email}</a>, um sich nach den für Ihr Wohnsitzland geeignetsten Zahlungsmethoden zu erkundigen.</>,
            "Der Mindesteinzahlungsbetrag beträgt 5 oder 10 EUR (oder der entsprechende Betrag in anderen verfügbaren Währungen), abhängig von der gewählten Zahlungsmethode. Die maximale Einzahlung kann je nach gewählter Zahlungsmethode von 200 EUR bis 1.000 EUR variieren.",
            "Das " + casino.name + " Casino akzeptiert nur Zahlungen, die mit den Optionen im Kassenbereich vorgenommen werden.",
            "Bitte stellen Sie sicher, dass Sie nur Einzahlungen mit einem Bankkonto, einer VISA-/MasterCard-Bankkarte, Zahlungswallets oder einer anderen auf Ihren Namen registrierten Zahlungsmethode vornehmen. Wenn während der Sicherheitsüberprüfungen festgestellt wird, dass Sie gegen diese Bedingung verstoßen haben, können Ihre Gewinne für ungültig erklärt und die ursprüngliche Einzahlung auf das Konto zurückerstattet werden, mit dem diese Einzahlung vorgenommen wurde.",
            "Das " + casino.name + " Casino behält sich das Recht vor, Transaktionen basierend auf Risikobewertungen zurückzuerstatten. In solchen Fällen wird die Einzahlung zurückerstattet und der Gutschriftbetrag, der der Einzahlung entspricht, vom Konto entfernt."
        ]
    },
    {
        title: "Auszahlungen",
        content: ["Bei " + casino.name + " sind wir immer begeistert, wenn unsere Spieler die Möglichkeit haben, ihre Gewinne abzuheben. Wir glauben, dass es in unserem besten Interesse ist, diesen Prozess so rational wie möglich zu gestalten."]
    },
    {
        title: "Für auszahlungen nötigen dokumente",
        content: ["Die nachfolgenden Dokumente müssen der Spielbank zur Bestätigung einer bevorstehenden Auszahlung vorgelegt werden. Es handelt sich um eine branchenübliche Standard-Prozedur, die nur einmal erforderlich ist. Bei den Dokumenten handelt es sich um:",
            <ul>
                <li>Eine Kopie von Ihrem Führerschein, Personalausweis oder Reisepass (Vorder- und Rückseite).</li>
                <li>Eine aktuelle Nebenkostenabrechnung (Strom oder Wasser), aus der Ihr Name und Ihre Adresse
                    entsprechend Ihrer Anmeldung im Spielbank ersichtlich sind.
                </li>
                <li>Eine Kopie der Kreditkarte (Vorder- und Rückseite), die Sie zur Einzahlung einsetzen. Bitte
                    decken Sie beim Kopieren alle Ziffern der Kreditkartennummer außer den ersten sechs und letzten
                    vier Ziffern ab.
                </li>
                <li>Eine unterschriebene Bestätigung der Auszahlungsanforderung (CAF, Card Authorization Form), die
                    Sie hier finden:<a href={cafRef.de} target="_blank" download> PDF</a>
                    .
                </li>
                <li>Am einfachsten ist es, vom Personalausweis oder Reisepass, von der Kreditkarte, der Strom- oder
                    Wasserabrechnung und dem CAF Digitalfotos zu machen und diese als E-Mail-Anhang an <a href={docRef}>{casino.support_documents}</a> zu senden. Bitte
                    vermerken Sie dabei Ihren richtigen Namen und Ihren Spielernamen.
                </li>
            </ul>]
    },
    {
        title: "Dokumente zum schutz vor missbrauch",
        content: ["Die Vorlage der oben genannten Dokumente kann auch zum Schutz vor eventuellem Missbrauch Ihres Kontos erforderlich werden.", "Bitte beachten Sie, dass die Spielbank sich das Recht vorbehält, jederzeit Dokumente zur Überprüfung der Richtigkeit der Angaben anzufordern, sofern dies für erforderlich gehalten wird.", <>Bevor
            die erforderlichen Dokumente eingereicht und von der Spielbank überprüft worden sind,
            ist eine
            Spielteilnahme nicht möglich. Dokumente zur Verifikation können auch per E-Mail an <a href={docRef}>{casino.support_documents}</a> geschickt
            werden.</>]
    }];

banking.es.sections = [
    {
        title: "",
        content: ["Bienvenido a la sección de Métodos de Pago donde se pueden ver todas las formas de como hacer un depósito y/o retirar dinero del casino . Todo depósito y recarga de dinero son seguros y llevan encriptación de datos SSL para asi asegurarte que tu información está 100% segura en todo momento. " + casino.name + " Casino está comprometido a proveerte transacciones bancarias seguras y fiables en todo momento garantizándote siempre prontitud en los pagos."]
    },
    {
        title: "Depósitos",
        content: [
            "El Casino " + casino.name + " acepta una variedad de métodos de depósito.",
            "Estos incluyen tarjetas de crédito y débito VISA y MasterCard, así como diferentes monederos electrónicos y vales. Por favor, ponte en contacto con nuestro equipo de soporte a través del soporte de chat 24/7 o en <a href={'mailto:'+casino.support_email}>{casino.support_email}</a> para consultar sobre los métodos de pago más adecuados para tu país de residencia.",
            "La cantidad mínima de depósito es de 5 o 10 EUR (o su equivalente en otras monedas disponibles) dependiendo del método de pago elegido. El depósito máximo puede variar desde 200 EUR hasta 1,000 EUR dependiendo del método de pago elegido.",
            "El Casino " + casino.name + " acepta únicamente pagos realizados con las opciones disponibles en el cajero.",
            "Por favor, asegúrate de realizar los depósitos solo utilizando una cuenta bancaria, tarjeta bancaria VISA/MasterCard, monederos electrónicos u cualquier otro método de pago registrado a tu nombre. Si se determina durante las verificaciones de seguridad que has violado esta condición, tus ganancias pueden ser anuladas y el depósito original puede ser reembolsado a la cuenta utilizada para realizar dicho depósito.",
            "El Casino " + casino.name + " se reserva el derecho de reembolsar transacciones basadas en evaluaciones de riesgo. En tales casos, el depósito es reembolsado y el crédito equivalente al depósito se elimina de la cuenta."
        ]
    },
    {
        title: "Documentos para retiros",
        content: ["Los siguientes documentos son requeridos por el casino con el fin de aprobar un retiro pendiente. Esta es una solicitud estándar de la industria, y sólo será necesaria enviarla una vez. Los documentos son:",
            <ul>
                <li>Una copia del Documento Nacional de Identidad, licencia de conducir o pasaporte (caras delantera
                    y trasera).
                </li>
                <li>Un reciente pago de servicios públicos que muestre el nombre del jugador y la dirección
                    registrada en la cuenta del casino.
                </li>
                <li>Copia de cualquier tarjeta de crédito ya utilizada en el casino para hacer un depósito (anverso
                    y reverso). Por favor, cubrir los ocho números del centroo dejando a la vista los cuatro
                    primeros y los últimos cuatro números.
                </li>
                <li>Un formulario de autorización de la tarjeta (CAF) que se encuentran aquí: <a href={cafRef.es} target="_blank" download>PDF</a></li>
                <li>La manera más popular para enviar estos documentos es simplemente tomando una fotografía digital
                    de su Documento de Identidad, Tarjeta de Crédito, facturas de servicios públicos y la
                    CAF, y enviarlo a <a href={docRef}>{casino.support_documents}</a> como un archivo
                    adjunto de correo electrónico.
                    Por favor, incluya su nombre y nombre de usuario.
                </li>
            </ul>]
    }, {
        title: "Documentos para la prevención del fraude",
        content: ["Los documentos antes mencionados también se pueden solicitar con fines de prevención de fraude o reapertura de cuentas.", "Tenga en cuenta que el casino se reserva el derecho de solicitar y requerir documentos de verificación a fin de validar cualquier cuenta de casino.", "Un jugador al cual se le requeiran estos documentos no podrá jugar hasta que se hayan recibido y aprobado por el casino todos los documentos necesarios. Los documentos de verificación también se pueden enviar por correo electrónico a <a \" href='mailto:documents@true-fortune.com'>documents@true-fortune.com </a>"]
    }];

banking.it.sections = [
    {
        title: "",
        content: ["Benvenuto alla sezione finanziaria dove potrai scoprire tutti I modi per effettuare un deposito o prelevare denaro dal casinò. Tutte le opzioni di deposito e prelievo sono sicure e protette con crittografia dei dati SSL per garantire che le vostre informazioni siano al sicuro in ogni momento. " + casino.name + " Casino si impegna a fornire transazioni sicure e affidabili in ogni momento pagamenti rapidissimi."]
    }, {
    title: "Depositi",
    content: [
        "Il Casinò " + casino.name + " accetta una varietà di metodi di deposito.",
        <>Questi includono carte di credito e debito VISA e MasterCard, così come diverse web wallet e buoni. Si prega di contattare il nostro team di supporto tramite il supporto Chat 24/7 o all'indirizzo <a href={'mailto:'+casino.support_email}>{casino.support_email}</a> per informazioni sui metodi di pagamento più adatti per il tuo paese di residenza.</>,
        "L'importo minimo di deposito è di 5 o 10 EUR (o l'equivalente in altre valute disponibili) a seconda del metodo di pagamento scelto. Il deposito massimo può variare da 200 EUR a 1.000 EUR a seconda del metodo di pagamento scelto.",
        "Il Casinò " + casino.name + " accetta solo pagamenti effettuati con le opzioni disponibili nella cassa.",
        "Assicurati di effettuare solo depositi utilizzando un conto bancario, una carta di credito/debito VISA/MasterCard, portafogli di pagamento o qualsiasi altro metodo di pagamento registrato a tuo nome. Se durante i controlli di sicurezza viene accertato che hai violato questa condizione, le tue vincite potrebbero essere annullate e il deposito originale potrebbe essere rimborsato sul conto utilizzato per effettuare tale deposito.",
        "Il Casinò " + casino.name + " si riserva il diritto di rimborsare le transazioni in base alle valutazioni del rischio. In tali casi, il deposito viene rimborsato e il credito equivalente al deposito viene rimosso dal conto."
    ]
},
    {
        title: "Documenti per prelievi",
        content: ["Per poter approvare una richiesta di prelievo, verranno richiesti dei documenti. Si tratta di una prassi che accomuna tutti i casinò online," +
        " e che verrà effettuata una sola volta. I documenti in questione sono:",
            <ul>
                <li>Una copia di un Documento d’Identità o passaporto (Fronte e retro).</li>
                <li>Una bolletta recente che mostra il nome del giocatore e l'indirizzo come appaiono nel conto.
                </li>
                <li>La copia della carta di credito utilizzata per effettuare il deposito nel casino (fronte e
                    retro). Si prega di coprire tutti i numeri eccetto le prime 4 e le ultime 4 cifre.
                </li>
                <li>Il CAF firmato (Lettera di autorizzazione) che potrai trovare qui: <a
                    href={cafRef.it} target="_blank" download>PDF </a></li>
                <li> "La maniera più semplice di inviare i documenti è semplicemente scattare una foto digitale del
                    vostro Documento d’Identità, carta di credito, bolletta e CAF
                    , e inviarlo a <a href={docRef}>{casino.support_documents}</a> come
                    allegato per e - mail.Si prega di inserire nell’ e - mail nome e nickname.
                </li>
            </ul>,]
    }, {
        title: "Documenti per prevenzione delle frodi",
        content: ["I documenti descritti in alto potranno essere richiesti anche per prevenire casi di frodi.", "Nota bene, il casinò si riserva il diritto di richiedere documenti di verifica in qualsiasi momento al fine di convalidare qualsiasi conto di gioco", "Il giocatore non potrà giocare fino a che I documenti richiesti non siano stati ricevuti e approvati dal casinò stesso."]
    }];

banking.pt.sections = [
    {
        title: "Depósitos",
        content: ["O Casino " + casino.name + " aceita uma variedade de métodos de depósito.",
            <>Isso inclui cartões de crédito e débito VISA e MasterCard, bem como diferentes carteiras digitais e vouchers. Por favor, entre em contato com nossa equipe de suporte através do suporte por chat 24/7 ou em <a href={'mailto:'+casino.support_email}>{casino.support_email}</a> para perguntar sobre os métodos de pagamento mais adequados para o seu país de residência.</>,
            "O valor mínimo de depósito é de 5 ou 10 EUR (ou o equivalente em outras moedas disponíveis) dependendo do método de pagamento escolhido. O depósito máximo pode variar de 200 EUR a 1.000 EUR, dependendo do método de pagamento escolhido.",
            "O Casino " + casino.name + " aceita apenas pagamentos feitos com as opções disponíveis no caixa.",
            "Por favor, certifique-se de fazer depósitos apenas usando uma conta bancária, cartão bancário VISA/MasterCard, carteiras digitais ou qualquer outro método de pagamento registrado em seu próprio nome. Se for determinado durante as verificações de segurança que você violou esta condição, seus ganhos podem ser anulados e o depósito original pode ser reembolsado na conta usada para fazer esse depósito.",
            "O Casino " + casino.name + " reserva-se o direito de reembolsar transações com base em avaliações de risco. Nesses casos, o depósito é reembolsado e o crédito equivalente ao depósito é removido da conta."
        ]
    },
    {
        title: "Levantamentos",
        content: ["No Casino " + casino.name + ", estamos sempre entusiasmados quando nossos jogadores têm a chance de sacar seus ganhos. Acreditamos que é do nosso melhor interesse tornar esse processo o mais eficiente possível.", "Os seguintes documentos são necessários pelo cassino para aprovar um saque pendente. Esta é uma solicitação padrão da indústria e será necessária apenas uma vez. Os documentos são:",
            <ul>
                <li>Uma cópia do RG ou passaporte (frente e verso).</li>
                <li>Uma conta recente de serviços públicos mostrando o nome e o endereço do jogador conforme constam na conta.</li>
                <li>Uma cópia de qualquer cartão de crédito ou débito usado para fazer um depósito (frente e verso). Por favor, cubra todos os números</li>
                <li>exceto os quatro primeiros e os quatro últimos.</li>
                <li>Um Formulário de Autorização de Cartão (CAF) assinado encontrado aqui: <a href={cafRef.pt} target="_blank" download>PDF</a>.</li>
            </ul>, "Se você usou um método de pagamento alternativo, como Neteller ou Skrill, você só precisará fornecer:",
            <ul>
                <li>Uma cópia do RG ou passaporte (frente e verso).</li>
                <li>Uma conta recente de serviços públicos mostrando o nome e o endereço do jogador conforme constam na conta.</li>
            </ul>, <>A melhor maneira de enviar esses documentos é tirar fotos digitais dos itens acima e enviá-los para <a href={docRef}>{casino.support_documents}</a>. Por favor, inclua
                seu nome e apelido e certifique-se de que as fotos não estejam muito desfocadas. Assim que o departamento financeiro revisar e aprovar os documentos de verificação, seu nome será incluído no próximo ciclo de pagamentos disponível.</>]
    }, {
        title: "Prevenção de Fraudes",
        content: ["Os documentos de verificação mencionados acima também podem ser solicitados para fins de prevenção de fraudes. O cassino reserva-se o direito de solicitar e exigir documentos de verificação para validar qualquer conta considerada essencial.", <>Por
            favor, observe que um jogador não poderá jogar até que os documentos necessários sejam recebidos e
            aprovados pelo cassino. Os Documentos de Verificação também podem ser enviados por e-mail para <a
                href={docRef}>{casino.support_documents}</a>.</>, "Ao completar uma transação, você automaticamente concorda com nossos Termos e Condições e Política de Privacidade.", "Uma taxa de processamento de 3% será aplicada em todos os depósitos."]
    }]

about.en.sections[0].content=[" "+casino.name+"  is an online casino owned and operated by an incorporated corporation organized licensed and regulated by the Government of Curacao for the purpose of operating an online casino.", "This website is operated under the gaming authority Curacao License #5536/JAZ.", "Playing at the casino, whether for fun play or real play constitutes an agreement between the player and the casino. The player will be subject to the following terms and conditions contained herein.", "Each clause contained in these terms and conditions shall be separate and severable from each of the others. If any clause is found to be void, invalid, or unenforceable for any reason, the remaining terms and conditions shall remain of full force and effect. The following Terms and Conditions govern the use of the  "+casino.name+" website and online casino:"]


export const casino_contents = {
    about,
    aml,
    banking,
    bitcoin,
    bonus,
    cookie,
    faq,
    privacy,
    responsible,
    security,
    support,
    terms,
    vip,
}

export const promos = {
    ////////////////////////////////////////////////////////////////////////////////
    bonus_slider_data: {
        en: [
            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / cover",  button: {style:{} ,text: "Play now"}, image:{ src:"/brand/images/bonus_images/1en.svg"}},
            {background:"url(/brand/images/bonus_images/2.png) no-repeat center center / cover",  button: { text: "Play now"},          image:{ src:"/brand/images/bonus_images/1en.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / cover" , button: { text: "Play now"},          image:{ src:"/brand/images/bonus_images/1en.svg"}},

        ],

        de: [
            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / cover",  button: { text: "JETZT SPIELEN"}, image:{ src:"/brand/images/bonus_images/1de.svg"}},
            {background:"url(/brand/images/bonus_images/2.png) no-repeat center center / cover",  button: { text: "JETZT SPIELEN"}, image:{ src:"/brand/images/bonus_images/1de.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / cover",  button: { text: "JETZT SPIELEN"}, image:{ src:"/brand/images/bonus_images/1de.svg"}},

        ],
        fr: [
            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / cover",  button: { text: "JOUEZ MAINTENANT"}, image:{ src:"/brand/images/bonus_images/1fr.svg"}},
            {background:"url(/brand/images/bonus_images/2.png) no-repeat center center / cover",  button: { text: "JOUEZ MAINTENANT"}, image:{ src:"/brand/images/bonus_images/1fr.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / cover",  button: { text: "JOUEZ MAINTENANT"}, image:{ src:"/brand/images/bonus_images/1fr.svg"}},

        ],
        it: [
            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / cover",  button: { text: "GIOCA ORA"}, image:{ src:"/brand/images/bonus_images/1it.svg"}},
            {background:"url(/brand/images/bonus_images/2.png) no-repeat center center / cover",  button: { text: "GIOCA ORA"}, image:{ src:"/brand/images/bonus_images/1it.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / cover",  button: { text: "GIOCA ORA"}, image:{ src:"/brand/images/bonus_images/1it.svg"}},

        ],
        es: [
            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / cover",  button: { text: "JUEGUE YA"}, image:{ src:"/brand/images/bonus_images/1es.svg"}},
            {background:"url(/brand/images/bonus_images/2.png) no-repeat center center / cover",  button: { text: "JUEGUE YA"}, image:{ src:"/brand/images/bonus_images/1es.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / cover",  button: { text: "JUEGUE YA"}, image:{ src:"/brand/images/bonus_images/1es.svg"}},

        ],
    },
    ////////////////////////////////////////////////////////////////////////////////
    promo_data: [
        {

            name: {
                en: "Welcome Bonus",
                de: "WILLKOMMENSBONUS",
                es: "BONO DE BIENVENIDA",
                fr: "BONUS DE BIENVENUE",
                it: 'BONUS DI BENVENUTO',
            },


            bonus_details: {
                en: "DEPOSIT BONUS",
                de: "EINZAHLUNGSBONUS",
                fr: "BONUS/DÉPÔT",
                es: "BONUS DE DEPÓSITO",
                it: 'BONUS DI DEPOSITO'
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>Your first deposit will be matched with 200% Welcome bonus or 100% cashback insurance.</h2>
                    <p>Here at Casino Pure Casino you can play with two types of depositing incentives. Deposit bonuses or Cashback Insurance.</p>
                    <p>Deposit bonuses are a bonus given to a player along with their deposit. Deposit bonuses are in essence a match bonus. Whatever amount you deposit, the casino will match. The higher the deposit amount, the higher your match bonus will be.</p>
                </>,
                de: <>
                    <p>Auf Ihre erste Einzahlung erhalten Sie einen 400% Willkommensbonus oder 100% Cashback-Versicherung.</p>
                    <p>Hier bei Pure Casino haben Sie zwei Optionen, um mit Einzahlungsangeboten zu spielen. Entweder mit einem Einzahlungsbonus oder mit einer Cashback-Versicherung.</p>
                    <p>Einzahungsboni sind Boni, die einem Spieler auf die Einzahlung angerechnet werden. Unabhängig davon, welchen Betrag Sie einzahlen, wird die Spielbank die Prozente des Bonus direkt anrechnen. Je höher der Einzahlungsbetrag, desto höher ist der gutgeschriebene Bonusbetrag.</p>
                    <p>Klicken Sie <a href="/terms">hier</a> für die AGB's des Willkommensbonus.</p> </>,
                fr: <><h2>Votre premier dépôt sera jumelé d'un Bonus de Bienvenue de 200% ou d'une assurance de 100% cashback.</h2>
                    <p>Ici sur Casino Pure Casino vous pouvez jouer avec deux types de bonus. Bonus sur Dépôt ou Assurance Cashback .</p>
                    <p>Les bonus sur dépôt sont un bonus donné à un joueur en fonction de son dépôt. Les bonus sur dépôt sont essentiellement un pourcentage du dépôt. Quel que soit le montant déposé, le bonus concordera. Plus vous déposez gros, plus gros le bonus sur dépôt sera.</p>
                    Cliquez <a href="/terms">ici</a> pour les termes et conditions de ce bonus </>,
                es: <>
                    <p>Su primer depósito será beneficiado con bono de Bienvenida de 400% o el 100% de reembolso de dinero real.</p>
                    <p>Aquí en Casino Pure Casino, puedes jugar con dos tipos de beneficios. Bono por depósito o seguros de reembolso de dinero</p>
                    <p>Bonos de depósito son un bono otorgado a un jugador junto con su depósito. Bonos de depósito son, en esencia, un Bono Combinado. Cualquier cantidad que usted deposita, el casino igualar. Cuanto mayor sea la cantidad del depósito, mayor será su bono .</p>
                    <p>Haga clic <a href="/terms">aquí</a> para ver los términos y condiciones de este bono.</p>  </>,
                it: <>
                    <p>Il primo deposito sara' abinato a un bonus del 200% o un'assicurazione del 100%</p>
                    <p>Qui al casino' Pure Casino puoi giocare con due tipi di incentivi di deposito. Deposito con Bonus o Cashback assicurazione.</p>
                    <p>I bonus di deposito sono bonus dati al giocatore per incrementare il suo deposito. I bonus di deposito sono essenzialmente bonus per giocare. Qualunque sia l'ammontare depositato, il casino' lo aggiungera'. Piu' alto e' l'importo del deposito piu' alto sara' il tuo bonus.</p>
                    <p>Clicca <a href="/terms">qui</a>per i termini e le condizioni di questo bonus</p>
                </>,

            }
        },
        {

            name: {
                en: "Welcome Bonus",
                de: "WILLKOMMENSBONUS",
                es: "BONO DE BIENVENIDA",
                fr: "BONUS DE BIENVENUE",
                it: 'BONUS DI BENVENUTO',
            },

            bonus_details: {
                en: "CASHBACK INSURANCE",
                de: "CASHBACK-VERSICHERUNG",
                fr: "ASSURANCE CASHBACK",
                es: "CASHBACK SEGUROS",
                it: 'ASSICURAZIONE CASHBACK'
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <>
                    <p>Cashback insurance is just as it sounds. Insurance should the house win. Feel confident as you play knowing that your money is guaranteed. Just speak with a support rep once your balance has finished, and they will add your real cashback to your account.</p>
                </>,
                de: <>
                    <p>Die Cashback-Versicherung ist genau das, wonach es sich anhört. Sollte das Haus gewinnen, greift die Versicherung. Sie brauchen sich beim Spielen keine Sorgen machen, denn Sie wissen, dass Ihr Geld abgesichert ist. Sprechen Sie einfach mit einem Support-Mitarbeiter, sobald Ihr Guthaben aufgebraucht ist, und er wird Ihnen die Cashback-Versicherung in der Kasse hinterlegen.</p>
                </>,
                fr: <>

                    <p>L'Assurance Cashback est exactement ce qu'elle parait. L'Assurance est créditée uniquement si la banque remporte la mise. Soyez rassure en jouant car votre argent est garanti. Contactez simplement un agent du support une fois que votre balance sera terminee afin qu'ils vous creditent votre cashback reel a votre compte.</p>
                </>,
                es: <>
                    <p>Seguro de Cashback es como suena.El seguro es en caso de que la casa gane.Siéntase seguro mientras que usted juega sabiendo que su dinero está garantizado. Sólo hable con un representante de soporte una vez que su balance se alla terminado y ellos agregaran su cashback en su cuenta.</p>
                </>,
                it: <>
                    <p>L'assicurazione cashback e' proprio come sembra. Assicurazione deve la vittoria alla casa. Sentirsi sicuri nel giocare sapendo che i tuoi soldi sono garantiti. Parla solo con il supporto se il tuo saldo finisce, e loro aggiungeranno nel tuo conto il cashback reale.</p>
                </>,
            }
        },
        {

            name: {
                en: "WEEKLY PROMOTIONS AND SPECIALS",
                de: "WÖCHENTLICHE AKTIONEN UND SPECIALS",
                es: "PROMOCIONES Y OFERTAS SEMANALES",
                fr: "PROMOTIONS ET OFFRES HEBDOMADAIRES",
                it: 'PROMOZIONI E SPECIALI SETTIMANALI',
            },

            bonus_details: {
                en: "NEXT DAY CASHBACK",
                de: "TURNIERE",
                fr: "REMBOURSEMENT LE LENDEMAIN",
                es: 'TORNEOS',
                it: 'CASHBACK DEL GIORNO DOPO'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <>
                    <p>Discover the craziness of our next day cashback special. You get a guarantee of up to 30% on all your deposits during the promotion period. The next day you get cash right back into your account.</p>
                </>,

                de: <>
                    <p>Pure Casino hält jeden Tag neue Aktionen und Angebote für Sie bereit. Nehmen Sie an unseren wöchentlichen Turnieren teil. Jeder Wetteinsatz, den Sie im Rahmen des Turniers machen, bringt Ihnen einen Turnierpunkt ein. Diejenigen mit den meisten Turnierpunkten gewinnen einen Anteil des 50000$ Gewinnpools.</p>
                </>,
                fr: <>
                    <p>Découvrez la folie de notre offre de remboursement du lendemain. Vous êtes garanti de recevoir 30 % de tous vos dépôts pendant la période de la promotion. Et le lendemain, vous récupérez l'argent directement sur votre compte.</p>
                </>,
                es: <>
                    <p>Todos los días en el Casino Pure Casino hay una nueva promoción disponible.</p>
                    <p>Participa en nuestros torneos semanales donde cada apuesta que hagas gana un Punto de torneo y los que tienga la mayor cantidad de puntos llegara a compartir el premio de 50K€</p>

                </>,
                it: <>
                    <p>Scopri la follia del nostro speciale cashback del giorno dopo. Ti garantiamo fino al 30% dei tuoi depositi durante il periodo della promozione. Il giorno successivo, il credito arriva dritto sul tuo account.</p>
                </>,
            }
        },
        {
            name: {
                en: "WEEKLY PROMOTIONS AND SPECIALS",
                de: "WÖCHENTLICHE AKTIONEN UND SPECIALS",
                es: "PROMOCIONES Y OFERTAS SEMANALES",
                fr: "PROMOTIONS ET OFFRES HEBDOMADAIRES",
                it: 'PROMOZIONI E SPECIALI SETTIMANALI',
            },

            bonus_details: {
                en: "VIP LOYALTY POINTS",
                de: "VERLOSUNG",
                fr: "POINTS DE FIDÉLITÉ VIP",
                es: 'RIFA',
                it: 'PUNTI FEDELTÀ VIP'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <>
                    <p>Pure Casino specialises in treating its loyal players like royalty. That is why we have introduced the COMP POINTS Rewards programme and it is automatically available to all Real Money account holders. The more you play, the more COMP POINTS you collect. These points are redeemable for CASH credits with no wagering requirements on them.</p>
                    <p>Start earning today!</p>
                    <p>For every bet you place at Pure Casino, you will earn points. Accumulate 1,000 comp-points and you will be able to redeem it for €/₤/$ 1.</p>
                </>,

                de: <>
                    <p>Nehmen Sie an einer unserer wöchentlichen Verlosungen teil. Jede Einzahlung i.H.v. 25 erhält automatisch ein Los. Je mehr Lose Sie haben, desto besser stehen die Chancen auf den Gewinn in Cash.</p>
                </>,
                fr: <>
                    <p>Pure Casino est spécialiste pour traiter ses joueurs fidèles comme de la royauté. C’est pour cette raison que nous avons introduit le programme des POINTS DE FIDÉLITÉ et il est automatiquement disponible à tous les propriétaires de compte en argent réel. Plus vous jouez, plus vous récupérez de POINTS DE FIDÉLITÉ. Ces points sont ensuite échangeables contre des crédits en ESPÈCE sans condition de mise.</p>
                    <p>Commencez à gagner dès aujourd’hui !</p>
                    <p>Vous gagnez des points pour chaque mise que vous placez sur Pure Casino. Accumulez 1 000 points de fidélité et pourrez récupérer 1 €/₤/$.</p>
                </>,
                es: <>
                    <p>Únete a uno de nuestros sorteos semanales. Cada depósito del 25€ gana un boleto para la rifa. Mientras más boletos ganan, mejores serán sus posibilidades de ganancias un premio en efectivo.</p>
                </>,
                it: <>
                    <p>Pure Casino è specializzato nel trattare i suoi giocatori più fedeli come dei re. Per questo abbiamo introdotto il programma di ricompensa con i PUNTI FEDELTÀ disponibile in automatico per tutti gli account in Denaro Reale. Più giochi, più PUNTI FEDELTÀ raccogli. Questi punti sono riscattabili in CREDITO e non sono soggetti a requisiti di puntata.
                    </p>
                    <p>Inizia a guadagnare oggi!</p>
                    <p>Per ogni puntata che effettui su Pure Casino, riceverai punti. Accumula 1.000 punti fedeltà e potrai riscattarli con €/₤/$ 1.</p>
                </>,
            }
        },
        {
            name: {
                es: "PROMOCIONES Y OFERTAS SEMANALES",
                de: "WÖCHENTLICHE AKTIONEN UND SPECIALS",
            },

            bonus_details: {
                de: "CASHPARTY",
                es:'CASHTRAVAGANZA'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {

                de: <>
                    <p>Entdecken Sie die Vorzüge unseres Cashparty-Specials. Dabei erhalten Sie eine Garantie von bis zu 80% auf all Ihre Einzahlungen während des Cashparty-Zeitraums. Am nächsten Tag erhalten Sie Ihr Bargeld direkt zurück in Ihre Kasse.</p>
                </>,
                es: <>
                    <p>Descubra la locura de nuestro especial Cashtravaganza. Usted obtiene una garantía de hasta un 80% en todos sus depósitos durante el período Cashtravaganza. Al día siguiente usted consigue el efectivo en su cuenta.</p>
                </>,
            }
        },
        {
            name: {
                es: "PROMOCIONES Y OFERTAS SEMANALES",
                de: "WÖCHENTLICHE AKTIONEN UND SPECIALS",
            },

            bonus_details: {
                de: "TREUEPUNKTE",
                es: "COMP POINTS",
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {

                de: <>
                    <p>Holen Sie sich Ihre Treuepunkte. Alle einzahlenden und berechtigten Spieler können Ihre Treuepunkte auf gewettetes Echtgeld anrechnen lassen. Die Treuepunkt-Rate beträgt 1Punkt pro 1€ Einsatz, wobei 100.000 Comp-Punkte in 100€ umgewandelt werden können.</p>
                </>,
                es: <>
                    <p>Consiga sus puntos de bonificación. Todos los depósitos y los jugadores elegibles y reclamar sus puntos de bonificación en dinero real apostado. La tasa de punto de un borrador es 1 punto comp por cada €1 apostado en 100.000 puntos de bonus pueden ser convertidos en €100.</p>
                </>,
            }
        },

    ],
    ////////////////////////////////////////////////////////////////////////////////
    jackpot: [
        {
            name: "Jim S. won",
            value: "$11,910.14",
            desc: "on at the Strike Gold "
        },
        {
            name: "Eve G. won",
            value: "$1.733.00",
            desc: "on at the copa "
        },
        {
            name: "Jordan W. won ",
            value: "$5,235.60",
            desc: "on at the Major Moolah "
        },
        {
            name: "John H. won",
            value: "$856.00",
            desc: "on at the Catsino "
        },
        {
            name: "Pam T. won ",
            value: "$29,990.58",
            desc: "on at the Money Magic"
        },
    ]
    ////////////////////////////////////////////////////////////////////////////////
}

export default casino_contents





